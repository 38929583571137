import React from 'react';
import { NavLink } from 'react-router-dom';
import './bubble-style.css';

const BubbleComponent = () => {
  return (
    <section className="bubble">
      <NavLink to={'https://api.whatsapp.com/send?phone=2615929037&text=Hola%21+🍷'} className="btn-whatsapp-pulse" target='_blank'> 
        <i className="fa-brands fa-whatsapp"></i>
      </NavLink>
    </section>
  );
};

export default BubbleComponent;