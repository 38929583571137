import React from 'react';
import './loader-component.css';
import logoArwine from '../../assets/images/logo/arwine-logo.png';
const LoaderComponent = () => {
  return (
    <>
      <div className='loader__bg d-flex align-items-center justify-content-center'>
        <div className="outer-circle shadow-lg">
          <div className="inner-circle d-flex align-items-center justify-content-center">
            <img src={logoArwine} alt="cargando..." className='loader__image' />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoaderComponent;