// Images
import img1 from '../../assets/images/main/gallery/image-1.jpg';
import img2 from '../../assets/images/main/gallery/image-2.jpg';
import img3 from '../../assets/images/main/gallery/image-3.png';
import img4 from '../../assets/images/main/gallery/image-4.png';
import img5 from '../../assets/images/main/gallery/image-5.png';
import img6 from '../../assets/images/main/gallery/image-6.png';
import img7 from '../../assets/images/main/gallery/image-7.png';
import img8 from '../../assets/images/main/gallery/image-8.png';
import img9 from '../../assets/images/main/gallery/image-9.png';

export const photos = [
    {
      src: img1,
      width: 4,
      height: 3
    },
    {
      src: img2,
      width: 1,
      height: 1
    },
    {
      src: img3,
      width: 3,
      height: 4
    },
    {
      src: img4,
      width: 3,
      height: 4
    },
    {
      src: img5,
      width: 3,
      height: 4
    },
    {
      src: img6,
      width: 4,
      height: 3
    },
    {
      src: img7,
      width: 3,
      height: 4
    },
    {
      src: img8,
      width: 4,
      height: 3
    },
    {
      src: img9,
      width: 4,
      height: 3
    }
  ];