import React from "react";
import { NavLink } from "react-router-dom";

const FooterLegals = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <div className="d-block">
        <p className="footer__evt" style={{ color: "#AF001F" }}>
          EVT: 18396
        </p>
      </div>
      <div className="d-block ">
        <div className="arwine__font footer__description">
          {" "}
          Turismo | Mendoza | Viaje{" "}
        </div>
      </div>
      <div className="d-block">
        <NavLink to={"https://hadarsoftware.com/"} target="_blank" className={"footer__hadar"} > Powered by: Hadar Software &#169; </NavLink> 
      </div>
    </div>
  );
};

export default FooterLegals;
