import React from "react";
import { routes } from "../../routes";
import NavbarLink from "./NavbarLink";
import NavbarFlags from "./NavbarFlags";
import { FormattedMessage } from "react-intl";


const NavbarOptions = ({ handleReset }) => {
  const { root, aboutUsView, privateTourView, contactView } = routes;

  const handleClick = () => {
    handleReset(false);
  };

  return (
    <ul className="navbar-nav">

      <button onClick={()=> handleClick()} className="buttonNavbar" > <NavbarLink route={root}> <FormattedMessage id='navbar.home' defaultMessage='Inicio' /> </NavbarLink> </button> 
      <button onClick={()=> handleClick()} className="buttonNavbar" > <NavbarLink route={aboutUsView}> <FormattedMessage id='navbar.aboutUs' defaultMessage='Nosotros' /> </NavbarLink> </button>
      <button onClick={()=> handleClick()} className="buttonNavbar" > <NavbarLink route={privateTourView}> <FormattedMessage id='navbar.privateTour' defaultMessage='Private Tour' /> </NavbarLink> </button>
      <button onClick={()=> handleClick()} className="buttonNavbar" > <NavbarLink route={contactView}> <FormattedMessage id='navbar.contact' defaultMessage='Contacto' /> </NavbarLink> </button>

      <li className="nav-item">
        <NavbarFlags />
      </li>

    </ul>
  );
};

export default NavbarOptions;
