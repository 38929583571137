import React from 'react';
import './footer-style.css';
import FooterSocial from './FooterSocial';
import FooterLegals from './FooterLegals';
import FooterLogo from './FooterLogo';
import useScreenSize from '../../utils/useScreenSize';
import FooterTitle from './FooterTitle';
import FooterLogoArgentina from './FooterLogoArgentina';
import BubbleComponent from '../Bubble/BubbleComponent';

const FooterComponent = () => {
  const { width } = useScreenSize();
  return (
    <div className="footer">

      <BubbleComponent />
      
      <div className="container">
        
        <div className="row">
          <div className="col-lg-4 col-12 d-flex flex-column align-items-center justify-content-start">
            <FooterTitle />
            <FooterLogoArgentina />
          </div>
          <div className="col-lg-4 col-12 d-flex align-items-end justify-content-center footer__legals mt-5 mb-4">
            {
              width >= 992 ? <FooterLegals /> : <FooterSocial />
            }
          </div>
          <div className="col-lg-4 col-12 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-row">
              {
                width >= 992 ? <FooterSocial /> : <FooterLegals />
              } 
              {
                width >= 992 ? <FooterLogo /> : ''
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FooterComponent;