import React from "react";
import NavbarOptions from "./NavbarOptions";
import NavbarLogo from "./NavbarLogo";
import NavbarToggler from "./NavbarToggler";
import { useState } from "react";

const NavbarComponent = () => {
  
  const [reset, setReset] = useState(true);

  const handleReset = (data) => {
    setReset(data);
  };

  return (
    reset ? 
    <nav className="navbar navbar-expand-md bg-body-tertiary fixed-top shadow-lg">
      <div className="container-fluid text-center arwine__font">
        <NavbarLogo handleReset={handleReset} />
        <NavbarToggler handleReset={handleReset} />
        <div className="collapse navbar-collapse" id="navbarNav">
          <NavbarOptions handleReset={handleReset} />
        </div>
      </div>
    </nav>
    : 
    <nav className="navbar navbar-expand-md bg-body-tertiary fixed-top shadow-lg">
      <div className="container-fluid text-center arwine__font">
        <NavbarLogo handleReset={handleReset} />
        <NavbarToggler handleReset={handleReset} />
        <div className="collapse navbar-collapse show" id="navbarNav">
          <NavbarOptions handleReset={handleReset} />
        </div>
      </div>
    </nav>

  );
};

export default NavbarComponent;
