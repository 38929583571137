import React, { useEffect } from 'react';
import FormComponent from '../components/Form/FormComponent';
// logo
import phoneLogo from '../assets/images/contact/phone-solid.svg';
import emailLogo from '../assets/images/contact/email.svg';
import instagramLogo from '../assets/images/contact/instagram.svg';
// css
import './styles/contact-view.css';
// AOS animations
import Aos from 'aos';
import'aos/dist/aos.css';
import CardContact from '../components/CardContact/CardContact';
import { FormattedMessage } from 'react-intl';

const ContactView = () => {
  // aos
  useEffect (()=>{
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section id='contact' className='contact'>
        <div className="container text-center">
          <div className="row">
            <div className="col-12 col-lg-4">
              <CardContact logo={phoneLogo}>
                Whatsapp <br />
                +54 9 2615929037
              </CardContact>
            </div>
            <div className="col-12 col-lg-4">
              <CardContact logo={emailLogo}>
                info@arwine.tur.ar
                demezzogustavo@gmail.com
              </CardContact> 
            </div>
            <div className="col-12 col-lg-4">
              <CardContact logo={instagramLogo}>
                Instagram <br />
                arwine.tours
              </CardContact> 
            </div>
          </div>
        </div>
      </section>
      <section className='mt-2'>
        <div className="container" data-aos="fade-up">
          <h3 className='arwine__font form__title'> <FormattedMessage id='contact.title' defaultMessage='Contactanos: ' /> </h3>
          <FormComponent />
        </div>
      </section>
    </>
  );
};

export default ContactView;