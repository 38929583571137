import React, { useRef, useState } from "react";
import './form-component.css';
import { FormattedMessage } from "react-intl";
import emailjs from '@emailjs/browser';
// toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

  const FormComponent = () => {
    const SERVICE_ID = "service_73vjwic";
    const TEMPLATE_ID = "template_rqolwf9";
    const PUBLIC_KEY = "q5O7Zh8gNYLws72O-";
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(''); 
    const [text, setText] = useState('');

    const form = useRef();

    // toastify
    const notify = (result) => {
      toast.success("Enviado!", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    };
    const notifyError = () => {
      toast.error("Error!", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    };
    const notifyInfo = () => {
      toast.info("Cargando...", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    };

    const sendEmail = (e) => {
      
      e.preventDefault();

      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
        .then((result) => {
          setName('');
          setLastName('');
          setEmail('');
          setPhone('');
          setText('');
          notify(result);
        }) 
        .catch((error) => {
          notifyError(error); 
        });

  };

  return (  
    <>
      <form className="form-floating arwine__font form__text mt-3 mb-5" ref={form} onSubmit={sendEmail}>
        <div className="row">
          <div className="form-floating">
            <input
              name="inputEmail"
              type="email"
              className="form-control mb-5"
              id="inputEmail"
              placeholder="name@example.com"
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
              required
            />
            <label htmlFor="inputEmail"> <FormattedMessage id='contact.text1-0' defaultMessage='Email' /> </label>
          </div>
        </div>
        <div className="row">
          <div className="form-floating mb-5 col-12 col-lg-6">
            <input
              name="inputName"
              type="text"
              className="form-control"
              id="inputName"
              placeholder="nombre"
              value={name}
              onChange={(e)=> setName(e.target.value)}
              required
            />
            <label htmlFor="inputName"><FormattedMessage id='contact.text1-1' defaultMessage='Nombre' /></label>
          </div>
          <div className="form-floating mb-5 col-12 col-lg-6">
            <input
              name="lastName"
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Apellido"
              value={lastName}
              onChange={(e)=> setLastName(e.target.value)}
              required
            />
            <label htmlFor="lastName"><FormattedMessage id='contact.text1-2' defaultMessage='Apellido' /></label>
          </div>
        </div>
        <div className="form-floating mb-5">
          <input
            name="phoneNumber"
            type="number"
            className="form-control"
            id="phoneNumber"
            placeholder="Numero de telefono"
            value={phone}
            onChange={(e)=> setPhone(e.target.value)}
            required
          />
          <label htmlFor="phoneNumber"><FormattedMessage id='contact.text1-3' defaultMessage='Nro de telefono' /></label>
        </div>
        <div className="form-floating mb-5">
          <textarea className="form-control" placeholder="consultas" id="floatingTextarea2" style={{height: "100px"}} name="inputText" value={text} onChange={(e)=> setText(e.target.value)} ></textarea>
          <label htmlFor="floatingTextarea2"><FormattedMessage id='contact.text1-4' defaultMessage='Consultas' /></label>
        </div>
        <button className="btn btn-danger" type="submit" onClick={()=>{notifyInfo()}} ><FormattedMessage id='contact.text1-5' defaultMessage='Enviar' /></button>
      </form>      
    </>
  );
};

export default FormComponent;
