import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo/arwine-logo.png'

const NavbarLogo = ({ handleReset }) => {
  const handleClick = () => {
    handleReset(false);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };
  return (
    <button className='buttonNavbar' onClick={()=>{handleClick()}}>
      <NavLink to={'./'} activeclassname='navbar__brand' className='navbar__brand'> <img src={logo} alt="Arwine" className='img-fluid' width="40" /> </NavLink>
    </button>
  );
};

export default NavbarLogo;