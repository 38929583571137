import React from 'react';
import { NavLink } from 'react-router-dom';

const NavbarLink = ({ route, children }) => {
  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };
  return (
    <li className="nav-item">
      <NavLink to={route} activeclassname='nav-link__active no-select' className='nav-link no-select' onClick={()=>{ handleScrollUp() }}> {children} </NavLink>     
    </li>
  );
};

export default NavbarLink;