import React from 'react';
import './App.css';
// react-router-dom routes
import { routes } from './routes';
import { HashRouter as BrowserRouter, Route, Routes } from 'react-router-dom'; // Para solucionar error 404
// views
import MainView from './views/MainView';
import AboutUsView from './views/AboutUsView';
import PrivateTourView from './views/PrivateTourView';
// components
import NavbarComponent from './components/Navbar/NavbarComponent';
import FooterComponent from './components/Footer/FooterComponent';
import ContactView from './views/ContactView';
import { ToastContainer } from 'react-toastify';

function App() {
  
  const { root, privateTourView, aboutUsView, contactView } = routes;

  return (
    <BrowserRouter>

        <NavbarComponent />
        <ToastContainer />
              
        <Routes>
          <Route exact path={root} element={<MainView/>} />
          <Route exact path={privateTourView} element={<PrivateTourView />} />
          <Route exact path={aboutUsView} element={<AboutUsView/>} />
          <Route exact path={contactView} element={<ContactView/>} />
        </Routes>
        
        <FooterComponent />

    </BrowserRouter>
  );
}

export default App;
