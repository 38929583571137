import React, { useEffect } from 'react';
// css
import './styles/private-tour.css';
// img
import wineTasting from '../assets/images/private-tour/wine-tasting.svg';
// AOS animations
import Aos from 'aos';
import'aos/dist/aos.css';
// intl
import { FormattedMessage } from 'react-intl';

const PrivateTourView = () => {
  // aos
  useEffect (()=>{
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section id='private-tour' className='private-tour'>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-12 d-flex align-items-center justify-content-center">
              <img src={wineTasting} alt="imagen animada" className='img-fluid' data-aos="fade-down"/>
            </div>
            <div className="col-md-7 col-12 d-flex align-items-center justify-content-center mt-5">
              <div className='arwine__font private-tour__text' data-aos="zoom-in">
                <FormattedMessage id='privateTour.text1-0' defaultMessage='Te ofrecemos tours privados a las mejores bodegas de Mendoza para disfrutar sus vinos y su gas  tronomía.' />
                 <br /> <br />
                <FormattedMessage id='privateTour.text1-1' defaultMessage='El tour de día completo incluye:' /> 
                <br/>
                <ul>
                  <li><FormattedMessage id='privateTour.text1-2' defaultMessage='Transporte en auto o van privada con chofer/guía bilingüe.' /></li>
                  <li><FormattedMessage id='privateTour.text1-3' defaultMessage='Visitas y degustaciones de vino en las mejores bodegas, hay más de 200 abiertas al turismo.' /></li>
                  <li><FormattedMessage id='privateTour.text1-4' defaultMessage='Almuerzo en bodegas con vino incluído.' /></li>
                  <li><FormattedMessage id='privateTour.text1-5' defaultMessage='Reservas en las bodegas.' /></li>
                </ul> 
                <br />
                <FormattedMessage id='privateTour.text1-6' defaultMessage='También organizamos paseos a la montaña, city tours, traslados desde y hacia el aeropuerto y mucho más.' />
                 <br /> <br />
                <FormattedMessage id='privateTour.text1-7' defaultMessage='Otra opción es que te podemos alquilar un automóvil o una van con nuestro chofer bilingüe y tú organizas las visitas a las bodegas sin tener que preocuparte por beber y conducir.' />
                 <br />
                <FormattedMessage id='privateTour.text1-8' defaultMessage='Para más información comunícate con nosotros y te ayudaremos a planificar días maravillosos en Mendoza.' />
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivateTourView;