import React from "react";

const NavbarToggler = ({ handleReset }) => {
  
  const handleClick = () => {
    handleReset(true);
  };

  return (
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={handleClick()}
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  );
};

export default NavbarToggler;
