import React, { useEffect } from 'react';
import winePhoto from '../assets/images/about-us/wine.jpg';
// css
import './styles/about-us-view.css';
// AOS animations
import Aos from 'aos';
import'aos/dist/aos.css';
import { FormattedMessage } from 'react-intl';

const AboutUsView = () => {
  // aos
  useEffect (()=>{
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section id='about-us__information' className='about-us__information about-us__margin-nav'>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6">
              <img src={winePhoto} alt="Imagen vino" className='img-fluid about-us__img' data-aos="flip-left"/>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <div className="about-us__info arwine__font" data-aos="fade-up">
                <FormattedMessage id='aboutUs.text1-0' defaultMessage='Creamos este proyecto en el 2016 para que visites bodegas y te deleites con sus vinos, disfrutes comidas deliciosas, aprecies montañas únicas y muchas otras bellezas que ofrece esta tierra.' />
              <br />
                <FormattedMessage id='aboutUs.text1-1' defaultMessage='Te invitamos a ser parte de la aventura y disfrutar.' /> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='about-us__divider' className='about-us__divider'>
      </section>
      <section id='about-us__text' className='about-us__text'>
        <div className="container d-flex align-items-center justify-content-center text-center">
          <div className="about-us__text-div arwine__font" data-aos="zoom-in">
            <FormattedMessage id='aboutUs.text2-0' defaultMessage='Queremos ayudarte a tener una experiencia maravillosa.' />
            <br />
            <FormattedMessage id='aboutUs.text2-1' defaultMessage='Bienvenido a Mendoza.' /> 
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsView;