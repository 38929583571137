import React from "react";
import logoArwine from '../../assets/images/logo/arwine-logo.png';
const FooterLogo = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <img
        src={logoArwine}
        alt="Arwine"
        style={{ width: "7rem" }}
        className="footer__mobile-clear"
      />
    </div>
  );
};

export default FooterLogo;
