import React, { useEffect } from 'react';
// AOS animations
import Aos from 'aos';
import'aos/dist/aos.css';
// CSS
import './styles/main-view.css';
// Img
import heroTitle from '../assets/images/main/hero-title.svg';
import grapes from '../assets/images/main/separacion-uva.png';
// Component
import GalleryComponent from '../components/Gallery/GalleryComponent';
// intl
import { FormattedMessage } from 'react-intl';
const MainView = () => {
  // aos
  useEffect (()=>{
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section id='hero' className='hero d-flex align-items-center justify-content-center'>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center hero__title">
            <img src={heroTitle} alt="Arwine Tours" className='img-fluid' data-aos="zoom-in" />
          </div>
        </div>
      </section>

      <section className='divider__grapes d-flex align-items-center justify-content-center'>
        <div className="container d-flex align-items-center justify-content-center">
          <img src={grapes} alt="imagen de uvas" className='img-fluid' data-aos="fade-down" />
        </div>
      </section>
      <section className='gallery'>
        <div className="container">
          <h3 className='arwine__font'><FormattedMessage id='main.title' defaultMessage='Nuestros recorridos: ' /></h3>
          <GalleryComponent />
        </div>
      </section>
    </>
  );
};

export default MainView;