import React, { useContext } from "react";
import argFlag from '../../assets/images/navbar/argentina-flag.png';
import brasilFlag from '../../assets/images/navbar/brasil-flag.png';
import estadosFlag from '../../assets/images/navbar/estados-unidos-flag.png';
import { langContext } from "../../context/langContext";

const NavbarFlags = () => {
  const width = {width: '3.2rem'};
  const idioma = useContext(langContext);
  return (
    <ul className="d-flex flex-row justify-content-center align-items-center">
      <li className="nav-link pointer no-select"> <button onClick={ ()=> idioma.establecerLenguaje('es-AR') } className="navbar__button" ><img src={argFlag} alt="Español" className="" style={width} /></button> </li>
      <li className="nav-link pointer no-select"> <button onClick={ ()=> idioma.establecerLenguaje('pt-BR') } className="navbar__button" ><img src={brasilFlag} alt="Portugues" className="" style={width} /></button> </li>
      <li className="nav-link pointer no-select"> <button onClick={ ()=> idioma.establecerLenguaje('en-US') } className="navbar__button" ><img src={estadosFlag} alt="Ingles" className="" style={width} /></button> </li>
    </ul>
  );
};

export default NavbarFlags;