import React from "react";
import wLogo from '../../assets/images/footer/whatsapp.svg';
import iLogo from '../../assets/images/footer/instagram.svg';
import eLogo from '../../assets/images/footer/envelope-regular.svg';
import { NavLink } from "react-router-dom";
const FooterSocial = () => {
  return (
    <div className="d-flex flex-row flex-lg-column footer__social">
      <NavLink to={"https://api.whatsapp.com/send?phone=2615929037&text=Hola%21+🍷"} target="_blank" > <img src={wLogo} alt="whatsapp logo" className="footer__space" style={{ width: "3.5rem" }}/> </NavLink>
      <NavLink to={"https://www.instagram.com/arwine.tours/"} target="_blank" > <img src={iLogo} alt="instagram logo" style={{ width: "3.5rem" }} /> </NavLink>
      <NavLink to={"mailto:info@arwine.tur.ar"} target="_blank" > <img src={eLogo} alt="email logo" style={{ width: "3.7rem" }} /> </NavLink>
    </div>
  );
};

export default FooterSocial;
