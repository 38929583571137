import React from 'react';
import './card-contact.css';
const CardContact = ({ logo, children }) => {
  return (
    <>
      <div className="rectangle" data-aos="fade-right">
        <img src={logo} alt="contacto" style={{width: '5rem'}} />
        <div className='arwine__font contact__text mt-5'>
          {children}
        </div>
      </div>
    </>
  );
};

export default CardContact;