import React, { useState } from "react";
import MensajesIngles from '../lang/en-US.json';
import MensajesCastellano from '../lang/es-AR.json';
import MensajesPortugues from '../lang/pt-BR.json';
import { IntlProvider } from "react-intl";
import LoaderComponent from "../components/Loader/LoaderComponent";

const langContext = React.createContext();

const LangProvider = ({children}) => {
  
  const [mensajes, setMensajes] = useState(MensajesCastellano);
  const [locale, setLocale] = useState('es-AR');
  const [cargando, setCargando] = useState(false);
  const establecerLenguaje = (lenguaje) => {
    switch (lenguaje) {
      case 'es-AR':
        setMensajes(MensajesCastellano);
        setLocale('es-AR');
        break;
      case 'en-US':
        setMensajes(MensajesIngles);
        setLocale('en-US');
        break;
      case 'pt-BR':
        setMensajes(MensajesPortugues);
        setLocale('pt-BR');
        break;    
      default:
        setMensajes(MensajesCastellano);
        setLocale('es-AR');
    } 
    setCargando(true);
    setTimeout(()=>{
      setCargando(false);
    }, 700);
  };

  return (
    <langContext.Provider value={{establecerLenguaje: establecerLenguaje}} >
      <IntlProvider locale={locale} messages={mensajes}>
        {cargando ? <LoaderComponent /> : children}
      </IntlProvider>
    </langContext.Provider>
  );
}

export {LangProvider, langContext};